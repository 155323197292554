
import faqs from '../config/faqs.json';

export interface IFaq {
    question: string,
    answer: string,
}

const FAQS: IFaq[] = faqs;

export { FAQS };