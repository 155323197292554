import React from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { useSelector, useDispatch } from "react-redux";
import { AlertSnackbar } from "./alert_snackbar";
import { FormControl, Button, CircularProgress } from "@material-ui/core";
import { StripeTextField } from "./stripe-text-field";
import {
  createPayment,
  selectPaymentError,
  selectIsRequestingPayment,
} from "../../slices/stripe/subscriptionSlice";

import { selectToken } from "../../slices/authenticationSlice";

import { selectProject } from "../../slices/projectSlice";

import { selectEmployeeCount, EmployeeLevel } from "../../slices/employeeSlice";

import { selectCountry } from "../../slices/countrySlice";

import "./payment-form.scss";

const PaymentForm = () => {
  const stripe = useStripe();

  const elements = useElements();

  const dispatch = useDispatch();

  const error = useSelector(selectPaymentError);

  const isLoading = useSelector(selectIsRequestingPayment);

  const token = useSelector(selectToken);

  const project = useSelector(selectProject);

  const employees = useSelector(selectEmployeeCount);

  const country = useSelector(selectCountry);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    // Stripe.js has not loaded yet. Make sure to disable
    // form submission until Stripe.js has loaded.
    if (!stripe || !elements) {
      return;
    }
    const cardEl = elements.getElement(CardNumberElement);

    if (
      !cardEl ||
      token === null ||
      project === undefined ||
      country === undefined
    ) {
      return;
    }
    dispatch(
      createPayment({
        token,
        stripe,
        element: cardEl,
        projectId: project.id,
        country: country.code,
        buckets: {
          low: employees[EmployeeLevel.AVERAGE],
          medium: employees[EmployeeLevel.MEDIUM],
          high: employees[EmployeeLevel.HIGH],
        },
      })
    );
  };

  return (
    <div className="payment-form">
      <h2 className="form-title">Credit Card</h2>
      {error !== null && <AlertSnackbar message={error} />}
      <form onSubmit={handleSubmit}>
        <FormControl fullWidth={true}>
          <div className={"form-row"}>
            <StripeTextField
              label="Card Number"
              data-test-id={"cardNumber"}
              inputProps={{
                options: {
                  showIcon: false,
                },
              }}
              stripeElement={CardNumberElement}
              variant={"filled"}
              className="payment-form-input"
            />
          </div>

          <div className={"form-row"}>
            <div className={"form-field"}>
              <StripeTextField
                label="Expiration date"
                stripeElement={CardExpiryElement}
                data-test-id={"expDate"}
                variant={"filled"}
                className="payment-form-input"
              />
            </div>
            <div className={"form-field"}>
              <StripeTextField
                label="CVC"
                stripeElement={CardCvcElement}
                data-test-id={"cvc"}
                variant={"filled"}
                className="payment-form-input"
              />
            </div>
          </div>

          <div className={"form-row"}>
            {!isLoading && (
              <Button
                className="btn btn-payment"
                type="submit"
                data-test-id={"submitPayment"}
                disabled={!stripe}
              >
                Confirm &amp; Pay
              </Button>
            )}
            {isLoading && (
              <Button
                type="submit"
                disabled={true}
                className="btn login-form-btn"
              >
                <CircularProgress />
              </Button>
            )}
          </div>
        </FormControl>
      </form>
    </div>
  );
};

export { PaymentForm };
