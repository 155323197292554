import React, { useEffect, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Introduction } from "../components/landing/introduction";
import { Process } from "../components/landing/process";
import { ICountry } from "../models/country";
import {
  country as updateCountry,
  selectCountry,
  selectIsPending,
  fetchCountry,
} from "../slices/countrySlice";
import { reset } from "../slices/employeeSlice";
import { CountryPicker } from "../components/landing/country-picker";
import { Page } from "../components/page";
import { Faqs } from "../components/faqs/faqs";
import { Section } from "../components/page/section";

const Home = function () {
  const title = "Earthly makes it easy for teams and small businesses to  invest in nature.";

  const ref = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  const history = useHistory();

  const country = useSelector(selectCountry);

  const isPending = useSelector(selectIsPending);

  useEffect(() => {
    document.title = `${title} | Earthly`;

    if (isPending) {
      dispatch(fetchCountry());
    }
  }, [title]);

  const onGetStartedTapped = useCallback(() => {
    ref.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  }, []);

  const onCountryChanged = useCallback(
    (country: ICountry) => {
      dispatch(updateCountry(country));
    },
    [dispatch]
  );

  const onNext = useCallback(() => {
    dispatch(reset());
    history.push(`/climate-positive-team/${country.code.toLowerCase()}`);
  }, [history, country, dispatch]);

  return (
    <Page className="home-landing">
      <Introduction
        title={title}
        subtitle={
          "Small teams can have a big positive impact. Start investing in nature today. Remove the personal carbon footprint of your employees, plus their business travel, to leave a positive impact."
        }
        introduction={
          "Restore nature, help reverse climate change, and show you’re a responsible business leader."
        }
        cta={"Subscribe from as little as £8.36 / month"}
        onClick={onGetStartedTapped}
      />

      <Process />
      <div ref={ref} />
      <CountryPicker
        country={country}
        enabled={!isPending}
        onChange={onCountryChanged}
        onNext={onNext}
        className="bkg-gray section-home-country"
      />
      <Section className="section-home-faq">

        <Faqs></Faqs>

      </Section>
    </Page>
  );
};

export { Home }; 
