import React, { useCallback, useState } from "react";
import { IProject, IAction, ISDGParam } from "../../models/project";
import { ProjectImage } from "./project-image";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import active from "../../icons/project-tick.svg";

import "./project-list-item.scss";

interface IProjectListItem {
  current?: IProject;
  project: IProject;
  onSelected: (project: IProject) => void;
}

const sdgs = (sdgs: ISDGParam[]) => {
  if (sdgs.length === 0) {
    return "";
  }
  return (
    <span className={"project-item-sdgs"}>
      <span className={"project-item-sdgs-first"}>| UN SDG's</span>
      <span className={"project-item-sdgs-count"}>{sdgs.length}</span>
    </span>
  );
};

interface IProjectActionProps {
  actions: IAction[];
}

const ProjectActions = ({ actions }: IProjectActionProps) => {
  if (actions.length === 0) {
    return <div />;
  }
  return (
    <div className="project-item-tags">
      {actions.map((tag: IAction, index: number) => (
        <span className="project-item-tag" key={index}>
          {tag.future}
        </span>
      ))}
    </div>
  );
};

interface IProjectSDGsParams {
  sdgs: ISDGParam[]
}



const SDG = (sdg: {name: string; image: string}) => (
  <div className={'project-sdgs-item'}  style={{
    backgroundImage: `url(${sdg.image})`}}>
    <span className='project-sdgs-item-title'>{sdg.name}</span>
  </div>
);

const ProjectSDGs = ({ sdgs }: IProjectSDGsParams) => {
  return (
    <div className='project-sdgs'>
      <h3>Project SDGs</h3>
      <div className='project-sdgs-grid'>
        {sdgs.map((sdg, i) => (<SDG key={i} name={sdg.name} image={sdg.image}></SDG>))}
      </div>
    </div>
  );
};

const ProjectListItem = ({
  current,
  project,
  onSelected,
}: IProjectListItem) => {
  const [expanded, setExpanded] = useState(false);

  const isSelected = project.id === current?.id;

  const title = expanded ? "Show less" : "Show more";

  const onClick = useCallback(() => {
    /*window.scroll({
      top: 650,
      behavior: 'smooth'
    })*/
    if (isSelected) {
      return;
    }
    onSelected(project);
  }, [isSelected, onSelected, project]);

  const projectPrice = (price: number) => (price / 100).toFixed(2);

  return (
    <div
      className={`project-item ${isSelected ? "project-item-active" : ""}`}
      onClick={onClick}
    >
      <div className={"project-item-top"}>
        <div className={"project-item-image-container"}>
          <ProjectImage
            className={"project-item-image"}
            src={`${project.image}`}
            alt={project.name}
          />
        </div>
        <div className={"project-item-details"}>
          <h4 className={"project-item-name"}>{project.displayName}</h4>
          <p className={"project-item-details"}>
            £{projectPrice(project.asset.tonnePrice)} per Tonne{" "}
            {sdgs(project.sdgs)}
          </p>
          <ProjectActions actions={project.actions} />
          <div className={"project-item-description"}
            dangerouslySetInnerHTML={{ __html: project.descriptionSummary }}>
          </div>
        </div>
        <div className={"project-item-selected"}>
          {isSelected && <img src={active} alt="Project selected" />}
        </div>
      </div>
      <div className={"project-item-bottom"}>
        <Accordion
          onChange={(event: object, expanded: boolean) => setExpanded(expanded)}
        >
          <AccordionSummary>
            <div className="project-item-show-more">
              {title}
              {!expanded && <ExpandMoreIcon />}
              {expanded && <ExpandLessIcon />}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div
              className="project-item-content"
              dangerouslySetInnerHTML={{ __html: project.description }}
            ></div>
            <ProjectSDGs sdgs={project.sdgs} />
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export { ProjectListItem };
