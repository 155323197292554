import React from "react";
import { useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import { IEmployeeCounts } from "../../slices/employeeSlice";
import { IProject } from "../../models/project";
import { ICountry } from "../../models/country";

import { selectPendingSubscription } from "../../slices/stripe/subscriptionSlice";
import { selectAuthenticated } from "../../slices/authenticationSlice";

import warning from "../../icons/warning.svg";

import "./project-basket.scss";

interface IProjectBasketProps {
  team: IEmployeeCounts;
  country: ICountry;
  project?: IProject;
  onSubmit: () => void;
}

interface IProjectCarbonLineItem {
  label: string;
  value: string;
  variant: string;
}

const total = (team: IEmployeeCounts, country: ICountry) =>
  country.low * team.average +
  country.medium * team.medium +
  country.high * team.high;

const CarbonLineItem = ({ label, value, variant }: IProjectCarbonLineItem) => (
  <div className={`project-basket-carbon-line project-basket-${variant}-line`}>
    <div
      className={`project-basket-carbon-line-label project-basket-${variant}-label`}
    >
      {label}
    </div>
    <div
      className={`project-basket-carbon-line-value project-basket-${variant}-value`}
    >
      <strong>{value}</strong>
    </div>
  </div>
);

interface IProjectDetailsProps {
  project?: IProject;
}

const ProjectDetails = ({ project }: IProjectDetailsProps) => {
  if (project === undefined) {
    return (
      <div className={"project-basket-project-details"}>
        <p className={"project-basket-project-name"}>
          Your project: <br />
          <br />
          <img src={warning} alt="Warning:" /> No project has been selected yet
        </p>
      </div>
    );
  }
  return (
    <div className={"project-basket-project-details"}>
      <p className={"project-basket-project-name"}>
        Your project: <br />
        <br />
        {project.displayName}
      </p>
      <div className={"project-basket-project-cost"}>
        <div className={"project-basket-project-cost-label"}>
          Cost per CO<sub>2</sub>t
        </div>
        <div className={"project-basket-project-cost-value"}>
          £{(project.asset.tonnePrice / 100).toFixed(2)}
        </div>
      </div>
    </div>
  );
};

const ProjectBasket = ({
  team,
  country,
  project,
  onSubmit,
}: IProjectBasketProps) => {
  const pending = useSelector(selectPendingSubscription);
  const authenticated = useSelector(selectAuthenticated);
  const buttonText = authenticated ? 'Proceed to payment' : 'Register';

  return (
    <div className={"project-basket"}>
      <h5 className={"project-basket-title"}>Your order</h5>
      <div className={"project-basket-team"}>
        <div className={"project-basket-team-header"}>
          <p className={"project-basket-team-title"}>Your team:</p>
          <p className={"project-basket-team-unit"}>
            Tonnes of CO<sub>2</sub>
          </p>
        </div>
        {team.average > 0 && (
          <CarbonLineItem
            variant={"team"}
            label={`Remote Workers x ${team.average}`}
            value={(country.low * team.average).toFixed(2)}
          />
        )}
        {team.medium > 0 && (
          <CarbonLineItem
            variant={"team"}
            label={`City-Hoppers x ${team.medium}`}
            value={(country.medium * team.medium).toFixed(2)}
          />
        )}
        {team.high > 0 && (
          <CarbonLineItem
            variant={"team"}
            label={`Globe-Trotters x ${team.high}`}
            value={(country.high * team.high).toFixed(2)}
          />
        )}
      </div>
      <CarbonLineItem
        variant={"total"}
        label={`Total`}
        value={total(team, country).toFixed(2)}
      />
      <div className={"project-basket-project"}>
        <ProjectDetails project={project}></ProjectDetails>
      </div>
      <div className={"project-basket-cost"}>
        <div className={"project-basket-cost-label"}>Total cost/month</div>
        <div className={"project-basket-cost-value"}>
          {pending === undefined && <span>£0.00</span>}
          {pending !== undefined && (
            <span>£{pending.monthlyPrice.toFixed(2)}</span>
          )}
        </div>
      </div>
      {
        authenticated &&
        <div className={"project-basket-submit"}>
          <Button
            onClick={onSubmit}
            className={"btn"}
            disabled={project === undefined}
          >
            { buttonText }
          </Button>
        </div>
      }
    </div>
  );
};

export { ProjectBasket };
