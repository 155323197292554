import React from 'react';

import './faqs.scss';
import { Row } from '../page/row';
import { Column } from '../page/column';
import { FAQS, IFaq } from '../../models/faq';
import { Accordion, AccordionSummary, AccordionDetails} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const faq = (faq: IFaq, index: number) => (
  <Accordion key={index}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <div className="faqs-question">{faq.question}</div>
    </AccordionSummary>
    <AccordionDetails>
      <div className="faqs-answer" dangerouslySetInnerHTML={{ __html: faq.answer }}></div>
    </AccordionDetails>
  </Accordion>
)

const Faqs = () => (
  <div className="faqs">
    <h4 className="faqs-title">FAQs</h4>
    <Row className="justify-content-md-center">
      <Column className="col-md-10">
        <h4 className="faqs-section-title">Invest in nature</h4>
        <div className="faqs-content">
          { FAQS.map((f, i) => faq(f,i)) }
        </div>
      </Column>
    </Row>
  </div>
);

export { Faqs };