import React from 'react';
import { IProject } from '../../models/project';
import { ProjectListItem } from './project-list-item';
import { CircularProgress } from '@material-ui/core';

import './project-list.scss';

interface IProejctListProps {
    current?: IProject,
    projects: IProject[],
    isLoading: boolean,
    onSelected: (project: IProject) => void,
}

const LoadingProjectList = () => (
    <div className={'projects-loading'}>
        <CircularProgress />
    </div>
);

const ProjectList = ({current, projects, isLoading, onSelected}: IProejctListProps) => (
    <div className={'project-list'}>
        { isLoading && <LoadingProjectList />}
        { !isLoading && projects.map((p, i) => (
            <ProjectListItem
                key={i}
                project={p}
                current={current}
                onSelected={onSelected} />
        ))}
    </div>
);

export { ProjectList };