import React, { useEffect, useState } from "react";
import { Page } from "../components/page";

import { Redirect, useLocation, useHistory } from "react-router-dom";
import { PageTitle } from "../components/page/page-title";
import { Section } from "../components/page/section";
import { WelcomeForm } from "../components/forms/welcome_form";
import { AccountTree } from "../components/accounts/account_tree";
import { selectToken } from "../slices/authenticationSlice";
import { PATHS } from "../components/routes";

import querystring from "query-string";
import { useSelector } from "react-redux";

const Welcome = () => {
  const history = useHistory();

  const title = "Welcome";

  const location = useLocation();
  const params = querystring.parse(location.search);
  const { email, inviteToken, userId } = params;

  const [redirect, setRedirect] = useState(false);

  const token = useSelector(selectToken);

  useEffect(() => {
    document.title = `${title} | Earthly`;

    if (token) {
      setRedirect(true);
    }
  }, [title, setRedirect, token]);

  if (redirect) {
    history.push(PATHS.ACCOUNT);
  }

  if (
    email === undefined ||
    inviteToken === undefined ||
    userId === undefined
  ) {
    return <Redirect to={"/404"} />;
  }

  return (
    <Page className="login">
      <Section>
        <div className="col-sm col-md-6">
          <PageTitle title={"Welcome to Earthly"} className={"trees"} />
        </div>
      </Section>
      <Section>
        <div className="col-sm col-md-6">
          <WelcomeForm
            email={email as string}
            token={inviteToken as string}
            user={userId as string}
          />
          <AccountTree />
        </div>
      </Section>
    </Page>
  );
};

export { Welcome };
