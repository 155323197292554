import React, { useEffect, useState } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { PATHS } from "../components/routes";
import { PageTitle } from "../components/page/page-title";
import { Column } from "../components/page/column";
import { Progress } from "../components/progress/progress";
import { Section } from "../components/page/section";
import { PaymentForm } from "../components/forms/payment-form";
import { OrderSummary } from "../components/order/summary";
import { profile as fetch, selectIdle } from "../slices/userSlice";
import { selectEmployeeCount } from "../slices/employeeSlice";
import { selectProject } from "../slices/projectSlice";
import { selectCountry } from "../slices/countrySlice";
import { selectToken } from "../slices/authenticationSlice";
import {selectPaymentAccepted, selectPaymentError} from "../slices/stripe/subscriptionSlice";
import { Page } from "../components/page";

const Payment = () => {
  const title = "Payment Details";

  const history = useHistory();

  const [redirect, setRedirect] = useState(false);

  const [complete, setComplete] = useState(false);

  const dispatch = useDispatch();

  const token = useSelector(selectToken);

  const project = useSelector(selectProject);

  const country = useSelector(selectCountry);

  const employees = useSelector(selectEmployeeCount);

  const awaitingProfile = useSelector(selectIdle);

  const paymentAccepted = useSelector(selectPaymentAccepted);

  const paymentError = useSelector(selectPaymentError)

  useEffect(() => {
    document.title = `${title} | Earthly`;

    if (token === null) {
      setRedirect(true);
    }

    if (project === undefined) {
      setRedirect(true);
    }

    if (awaitingProfile && token) {
      dispatch(fetch(token));
    }

    if (paymentAccepted && !paymentError) {
      setComplete(true);
    }

  }, [
    title,
    history,
    project,
    token,
    awaitingProfile,
    paymentAccepted,
    dispatch,
    setComplete,
  ]);

  if (redirect) {
    return <Redirect to={PATHS.PROJECT} />;
  }

  if (complete) {
    history.push(PATHS.COMPLETION);
  }

  return (
    <Page className="payment">
      <Section>
        <Progress current={4} />
      </Section>
      <Section>
        <Column className="col col-lg-6">
          <PageTitle title={`Confirm & Pay`} className={"payment"} />
          <PaymentForm />
        </Column>
        <Column className="col col-lg-6">
          <OrderSummary team={employees} country={country} project={project} />
        </Column>
      </Section>
    </Page>
  );
};

export { Payment };
