import React from "react";
import { useSelector } from "react-redux";
import { IEmployeeCounts } from "../../slices/employeeSlice";
import { selectPendingSubscription } from "../../slices/stripe/subscriptionSlice";
import { IProject } from "../../models/project";
import { ICountry } from "../../models/country";

import warning from "../../icons/warning.svg";

import "./summary.scss";

interface IProjectBasketProps {
  team: IEmployeeCounts;
  country: ICountry;
  project?: IProject;
}

interface IProjectCarbonLineItem {
  label: string;
  value: string;
  variant: string;
  cost: string;
}

interface IProjectDetailsProps {
  project?: IProject;
}

const total = (team: IEmployeeCounts, country: ICountry) =>
  country.low * team.average +
  country.medium * team.medium +
  country.high * team.high;

const projectCost = (project: IProject, co2Total: number) =>
  ((co2Total * (project.asset.tonnePrice / 100)) / 12).toFixed(2);

const CarbonLineItem = ({
  label,
  cost,
  value,
  variant,
}: IProjectCarbonLineItem) => (
  <tr className={`order-summary-carbon-line order-summary-${variant}-line`}>
    <td
      className={`order-summary-carbon-line-label order-summary-${variant}-label`}
    >
      {label}
    </td>
    <td
      className={`order-summary-carbon-line-value order-summary-${variant}-value`}
    >
      {value}
    </td>
    <td
      className={`order-summary-carbon-line-value order-summary-${variant}-cost`}
    >
      £{cost}
    </td>
  </tr>
);

const carbonCalculation = (value: number, quantity: number) =>
  (value * quantity).toFixed(2);

const ProjectDetails = ({ project }: IProjectDetailsProps) => {
  if (project === undefined) {
    return (
      <p className={"order-summary-no-project"}>
        <img src={warning} alt="Warning:" /> No project has been selected yet
      </p>
    );
  }
  return (
    <div className={"order-summary-project-details"}>
      <p className={"order-summary-project-title"}>Project Details</p>
      <p className={"order-summary-project-value"}>{project.displayName}</p>
      <div className={"order-summary-project-cost"}>
        <div className={"order-summary-project-cost-label"}>
          Price per Tonne of CO<sub>2</sub>
        </div>
        <div className={"order-summary-project-cost-value"}>
          £{(project.asset.tonnePrice / 100).toFixed(2)}
        </div>
      </div>
    </div>
  );
};

const OrderSummary = ({ team, country, project }: IProjectBasketProps) => {
  const pending = useSelector(selectPendingSubscription);

  if (project === undefined) {
    return <div />;
  }

  return (
    <div className={"order-summary"}>
      <h5 className={"order-summary-title"}>Order summary</h5>
      <div className={"order-summary-product"}>
        <p className={"order-summary-product-title"}>Product:</p>
        <p className={"order-summary-product-value"}>Earthly Subscription</p>
      </div>
      <div className={"order-summary-team"}>
        <p className={"order-summary-team-title"}>Subscription Details</p>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>
                Tonnes of CO<sub>2</sub>
              </th>
              <th>Cost / month</th>
            </tr>
          </thead>
          <tbody>
            {team.average > 0 && (
              <CarbonLineItem
                variant={"team"}
                label={`Remote Workers x ${team.average}`}
                cost={projectCost(project, country.low * team.average)}
                value={carbonCalculation(country.low, team.average)}
              />
            )}
            {team.medium > 0 && (
              <CarbonLineItem
                variant={"team"}
                label={`City-Hoppers x ${team.medium}`}
                cost={projectCost(project, country.medium * team.medium)}
                value={carbonCalculation(country.medium, team.medium)}
              />
            )}
            {team.high > 0 && (
              <CarbonLineItem
                variant={"team"}
                label={`Globe-Trotters x ${team.high}`}
                cost={projectCost(project, country.high * team.high)}
                value={carbonCalculation(country.high, team.high)}
              />
            )}
          </tbody>
          <tfoot>
            <CarbonLineItem
              variant={"total"}
              label={`Total footprint`}
              cost={pending ? pending.monthlyPrice.toFixed(2) : "0.00"}
              value={total(team, country).toFixed(2)}
            />
          </tfoot>
        </table>
      </div>

      <div className={"order-summary-project"}>
        <ProjectDetails project={project}></ProjectDetails>
      </div>
      <div className={"order-summary-cost"}>
        <div className={"order-summary-cost-label"}>Total cost/month</div>
        <div className={"order-summary-cost-value"}>
          {pending === undefined && <span>£0.00</span>}
          {pending !== undefined && (
            <span>£{pending.monthlyPrice.toFixed(2)}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export { OrderSummary };
