
import React from 'react';

import { useCopyToClipboard } from '../../utils/copy-to-clipboard';
import copyIcon from '../../icons/copy.svg';
import check from '../../icons/check.svg';

import './api_keys.scss';

interface IApiKey {
  date: string,
  key: string,
}

interface IApiKeyItemProps {
  item: IApiKey
}

interface IApiKeysProps {
  items: IApiKey[]
}

const ApiKey = ({item}: IApiKeyItemProps) => {
  const {isCopied, handle} = useCopyToClipboard(2000);

  return (
    <tr className='api-key-item'>
      <td className='api-key-item-date'><span className='api-key-item-border'>{item.date}</span></td>
      <td className='api-key-item-title'>{item.key}</td>
      <td className='api-key-item-download'>
        <button className='api-key-item-button' onClick={() => handle(item.key)}>
          {isCopied ? <img src={check} alt='Copied' /> : <img src={copyIcon} alt='Copy to Clipboard' />}
        </button>
      </td>
    </tr>
  );
};


const ApiKeys = ({items}: IApiKeysProps) => (
  <div className='api-key'>
    <h2 className='api-key-header'>API Keys</h2>

    {(items.length === 0) && (<p className='api-key-alert'>No API Keys availalble</p>)}
    {items.length > 0 && (
      <div className='api-key-items'>
        <table>
          <thead>
            <tr>
              <th className='api-key-item-header'>Date</th>
              <th className='api-key-item-header'>API Key</th>
              <th className='api-key-item-header'>Copy</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => <ApiKey item={item} key={index} />)}
          </tbody>
        </table>
      </div>
    )}
  </div>
);

export { ApiKeys };