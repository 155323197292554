import React, { useEffect, useCallback, useState } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PageTitle } from "../components/page/page-title";
import { Progress } from "../components/progress/progress";
import { Page } from "../components/page";
import { Section } from "../components/page/section";
import { Column } from "../components/page/column";
import {
  fetchProjects,
  selectPending,
  selectReady,
  selectProject,
  selectAvailableProjects,
} from "../slices/projectSlice"; 

import { selectCountry } from "../slices/countrySlice";

import { calculateSubscription } from "../slices/stripe/subscriptionSlice";

import { EmployeeLevel, selectEmployeeCount } from "../slices/employeeSlice";
import { PATHS } from "../components/routes";
import { ProjectList } from "../components/projects/project-list";
import { ProjectBasket } from "../components/projects/project-basket";
import { project as pickProject } from "../slices/projectSlice";
import { selectTeamSelected } from "../slices/employeeSlice";
import { IProject } from "../models/project";

import scales from "../icons/process_select.png";
import {Login as LoginForm} from "../components/forms/login_form";
import {Button} from "@material-ui/core";
import {selectAuthenticated} from "../slices/authenticationSlice";


const ProjectSelection = function () {
  const dispatch = useDispatch();

  const history = useHistory();

  const title = "Select your project";

  const country = useSelector(selectCountry);

  const employees = useSelector(selectEmployeeCount);

  const project = useSelector(selectProject);

  const authenticated = useSelector(selectAuthenticated);

  const projects = useSelector(selectAvailableProjects);

  const isReady = useSelector(selectReady);

  const isPending = useSelector(selectPending);

  const hasTeamReady = useSelector(selectTeamSelected);

  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    document.title = `${title} | Earthly`;

    if (!hasTeamReady) {
      setRedirect(true);
    }

    if (isPending) {
      dispatch(fetchProjects());
    }
  }, [isPending, dispatch, hasTeamReady]);

  const onSubmit = useCallback(() => {
    history.push(PATHS.REGISTER);
  }, [history]);

  const goRegister = useCallback(() => {
    history.push(PATHS.REGISTER);
  }, [history]);

  const onProjectSelected = useCallback(
    (project: IProject) => {
      dispatch(pickProject(project));
      dispatch(
        calculateSubscription({
          projectId: project.id,
          country: country.code,
          buckets: {
            low: employees[EmployeeLevel.AVERAGE],
            medium: employees[EmployeeLevel.MEDIUM],
            high: employees[EmployeeLevel.HIGH],
          },
        })
      );
    },
    [dispatch, country, employees]
  );

  const carbonFootprint = () =>
    (
      employees[EmployeeLevel.AVERAGE] * country.low +
      employees[EmployeeLevel.MEDIUM] * country.medium +
      employees[EmployeeLevel.HIGH] * country.high
    ).toFixed(2);

  if (redirect) {
    return <Redirect to={PATHS.HOME} />;
  }

  return (
    <Page className="projects">
      <Section>
        <Progress current={2} />
        <div className="progress-img-wrapper">
        <img src={scales} alt="Carbon" />
        </div>
        <h2 className={"carbon"}>We recommend that you balance <b>{carbonFootprint()} tonnes </b>of carbon as your investment in nature.</h2>
       {/* <PageTitle
          title={`We recommend that you balance <b>{carbonFootprint()} tonnes </b>of carbon as your investment in nature.`}
          className={"carbon"}
        >
          <EarthlyTooltip
            className={"tooltip-carbon"}
            text={
              "Your total tonnes of carbon is calculated using the average footprint in your location and the total number of employees you have selected"
            }
          /> 
        </PageTitle>*/}
        
      </Section>
      <Section>
        <Column className="col-lg-8">
          <PageTitle title={title} className={"projects"}/>
        </Column>
      </Section>
      <Section>
        <Column className="col-lg-8">
          <div className={ authenticated || (project === undefined) ? 'bg-white d-none' : 'bg-white'}>
            <PageTitle title="Need an account?" className={"projects mt-0"}/>
            <Button
              onClick={goRegister}
              className={"login-form-btn btn"}
              disabled={project === undefined}
            >
              {"Create an account"}
            </Button>
            or
            <h3 className="page-subtitle">Log In</h3>
            <div className="pl-4 pr-4 mb-4">
              <LoginForm includeForgottenLink={true} includeSignup={false} />
            </div>
          </div>

          <ProjectList
            projects={projects}
            current={project}
            isLoading={!isReady}
            onSelected={onProjectSelected}
          ></ProjectList>
        </Column>
        <Column className="col-lg-4">
          <ProjectBasket
            project={project}
            country={country}
            team={employees}
            onSubmit={onSubmit}
          ></ProjectBasket>
        </Column>
      </Section>
    </Page>
  );
};

export { ProjectSelection };
