
import React from 'react';

import './documentation.scss';

const Documentation = () => (
  <div className='api-documentation'>
    <h2 className='api-documentation-header'>Documentation</h2>

    <p className='api-documentation-text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus commodo vel lacus in gravida. Fusce vitae aliquam metus. Curabitur tincidunt ultrices dignissim. Curabitur malesuada libero et tempus consectetur. Integer porttitor lacus ipsum, vitae faucibus elit tempus a.</p>
    <p className='api-documentation-box'>
      <a href={'https://earthly.org'} className='api-documentation-link'>Link to documentation</a>
    </p>
  </div>
);

export { Documentation };