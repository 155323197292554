import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Page } from "../../components/page";
import { Column } from "../../components/page/column";
import { Section } from "../../components/page/section";
import { AccountHeader } from "../../components/accounts/header";
import { AccountNavigation } from "../../components/accounts/navigation";
import { ApiKeys as ApiKeysComp } from "../../components/accounts/api_keys";
import { Documentation } from "../../components/accounts/documentation";
import { profile as fetch, selectIdle } from "../../slices/userSlice";
import { selectToken } from "../../slices/authenticationSlice";

const ApiKeys = function () {
  const title = "API Keys";

  const dispatch = useDispatch();

  const token = useSelector(selectToken);

  const awaitingProfile = useSelector(selectIdle);

  useEffect(() => {
    document.title = `${title} | Earthly`;

    if (awaitingProfile && token) {
      dispatch(fetch(token));
    }
  }, [title, dispatch, token, awaitingProfile]);

  return (
    <Page className="login">
      <AccountHeader />
      <Section>
        <Column className="col col-lg-4">
          <AccountNavigation current="api" />
        </Column>
        <Column className="col col-lg-8">
          <ApiKeysComp items={[]} />
          <Documentation />
        </Column>
      </Section>
    </Page>
  );
};

export { ApiKeys };
